import { Component } from '@angular/core';
import {environment} from '../environments/environment';

@Component({
  selector: 'toycc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'toyota-consent-centre-frontend-master';
  constructor() {
    console.log('AppComponent start');
    if (!environment.logEnabled) {
      console.log = () => {};
    }
  }
}
