import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from './data-table.component';
import { DataTableHeaderComponent } from './data-table-header/data-table-header.component';
import { DataTablePaginationComponent } from './data-table-pagination/data-table-pagination.component';
import { DataTableColumnComponent } from './data-table-column/data-table-column.component';
import { PaginatePipe } from './pipes/paginate.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FontAwesomeModule],
  declarations: [
    DataTableComponent,
    DataTableHeaderComponent,
    DataTablePaginationComponent,
    DataTableColumnComponent,
    PaginatePipe,
  ],
  exports: [DataTableComponent, DataTableColumnComponent],
})
export class DataTableModule {}
