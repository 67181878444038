import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Dealer } from '../models/helpers/dealer';
import { environment } from '../../../environments/environment';
import {DealerDataChangeRequest} from '../models/helpers/dealer-data-change-request';
import {CreateDealerRequest} from '../models/helpers/create-dealer-request';
import {DeleteDealerRequest} from '../models/requests/delete-dealer-request';
import {ChangeDealerNumberRequest} from '../models/requests/change-dealer-number-request';
import {ChangeDealerGroupRequest} from '../models/requests/change-dealer-group-request';
import {KeycloakCredentials} from '../models/helpers/keycloak-credentials';
import {ReloadConsentRelationshipsRequest} from '../models/helpers/reload-consent-relationsships-request';

@Injectable({
  providedIn: 'root'
})
export class DealerService {

  constructor(private http: HttpClient) { }

  /**
   * Find a dealer by its dealer number
   */
  findDealerByDealerNumberAndNotDeleted(dealerNumber: string): Observable<Dealer> {
    const url = environment.endpoints.DEALER.getDealerByNumberAndNotDeleted.replace('{dealerNumber}', dealerNumber);
    return this.http.get<Dealer>(url);
  }

  /**
   * Find a dealer by its dealer number
   */
  findDealerByDealerNumber(dealerNumber: string): Observable<Dealer> {
    const url = environment.endpoints.DEALER.getDealerByDealerNumber.replace('{dealerNumber}', dealerNumber);
    return this.http.get<Dealer>(url);
  }

  updateDealer(dealerId: string, dealer: DealerDataChangeRequest): Observable<Dealer> {
    const UPDATE_URL = environment.endpoints.DEALER.update.replace('{id}', dealerId);
    return this.http.put<Dealer>(UPDATE_URL, dealer);
  }

  createDealer(dealer: CreateDealerRequest): Observable<Dealer> {
    const CREATE_URL = environment.endpoints.DEALER.create;
    return this.http.post<Dealer>(CREATE_URL, dealer);
  }

  deleteDealer(dealerNumber: string, newPrefDealerNumber: string): Observable<Dealer> {
    const DELETE_URL = environment.endpoints.DEALER.delete
      .replace('{dealerNumber}', dealerNumber)
      .replace('{newPrefDealerNumber}', newPrefDealerNumber);
    return this.http.post<Dealer>(DELETE_URL, null);
  }

  changeDealerNumber(dealerId: string, changeDealerNumberRequest: ChangeDealerNumberRequest): Observable<Dealer> {
    const CHANGE_DEALER_NUMBER_URL = environment.endpoints.DEALER.changeDealerNumber.replace('{id}', dealerId);
    return this.http.put<Dealer>(CHANGE_DEALER_NUMBER_URL, changeDealerNumberRequest);
  }

  changeDealerNumberMainDealer(dealerId: string, oldNumberString: string, newNumberString: string): Observable<Dealer> {
    const url = environment.endpoints.DEALER.changeDealerNumberMainDealer.replace('{id}', dealerId);
    return this.http.put<Dealer>(url, {newDealerNumber: newNumberString, oldDealerNumber: oldNumberString});
  }

  changeDealerGroup(dealerId: string, changeDealerGroupRequest: ChangeDealerGroupRequest): Observable<Dealer> {
    const CHANGE_DEALER_GROUP_URL = environment.endpoints.DEALER.changeDealerGroup.replace('{id}', dealerId);
    return this.http.put<Dealer>(CHANGE_DEALER_GROUP_URL, changeDealerGroupRequest);
  }

  getKeycloakCredentials(dealerNumber: string): Observable<KeycloakCredentials[]> {
    const getKeycloakCredentialsUrl = environment.endpoints.DEALER.getKeycloakCredentialsUrl.replace('{dealerNumber}', dealerNumber);
    return this.http.get<KeycloakCredentials[]>(getKeycloakCredentialsUrl);
  }

  generateClient(aClientId: string): Observable<KeycloakCredentials[]> {
    const generateClientUrl = environment.endpoints.DEALER.generateKeycloakClientUrl;
    return this.http.post<KeycloakCredentials[]>(generateClientUrl, {
      clientId: aClientId,
      role: null,
      allowTokenExchange: false
    });
  }

  reloadConsentRelationShips(dealerId: string, request: ReloadConsentRelationshipsRequest): Observable<Dealer> {
    const url = environment.endpoints.DEALER.reloadConsentRelationShips.replace('{id}', dealerId);
    return this.http.put<Dealer>(url, request);
  }

  getOpenDealerDialogueLink(dealerNumber: string, tecCustomerId: string): Observable<{link: string}> {
    const url = environment.endpoints.DEALER.getOpenDealerDialogueLink
        .replace('{tecCustomerId}', tecCustomerId)
        .replace('{dealerNumber}', dealerNumber);
    return this.http.get<{link: string}>(url);
  }
}
