const SERVER_BASE_URL =
  'https://service.master.tcc-integration.toyota.itsd-consulting.services';
const SERVER_BASE_URL_FILE =
  'https://file.tcc-integration.toyota.itsd-consulting.services';

export const environment = {
  production: false,
  logEnabled: true,
  isDev: true,
  environmentName: 'Integration-System',
  country: 'DE',
  auth: {
    access_token: 'consent_centre_master_access_token',
  },
  keycloak: {
    url: `https://keycloak-integration.itsd-consulting.services`,
    realm: 'consent-centre',
    clientId: 'master-access',
  },
  endpoints: {
    addAction: `${SERVER_BASE_URL}/customer/add-action`,
    updateApi: `${SERVER_BASE_URL}/customer-consent/{consentId}`,
    loadActionQueue: `${SERVER_BASE_URL}/customer/load-action-queue`,
    deleteActionQueueItems: `${SERVER_BASE_URL}/customer/delete-action-queue-items`,
    exportActionQueueItems: `${SERVER_BASE_URL}/customer/export-action-queue-items`,
    WEBHOOKS: {
      createWebhookReceiver: `${SERVER_BASE_URL}/webhooks`,
      updateWebhookReceiver: `${SERVER_BASE_URL}/webhooks/{id}`,
      loadWebhookReceiverList: `${SERVER_BASE_URL}/webhooks/load-webhook-receiver-list`,
      deleteWebhookReceiverList: `${SERVER_BASE_URL}/webhooks/delete-webhook-receiver-list`,
      activateWebhookReceiverList: `${SERVER_BASE_URL}/webhooks/activate-webhook-receiver-list`,
      deactivateWebhookReceiverList: `${SERVER_BASE_URL}/webhooks/deactivate-webhook-receiver-list`,
      exportWebhookReceiverList: `${SERVER_BASE_URL}/webhooks/export-webhook-receiver-list`,
      uploadCSV: `${SERVER_BASE_URL}/webhooks/upload-csv`,
    },
    CUSTOMER_CONSENT: {
      findByTecCustomerId: `${SERVER_BASE_URL}/customer-consent/customerId/`,
    },
    CUSTOMER: {
      getNextCustomerDuplicate: `${SERVER_BASE_URL}/customer-duplicate/get-next`,
      getCustomerDuplicate: `${SERVER_BASE_URL}/customer-duplicate/{customerTecCustomerId}/{duplicateTecCustomerId}`,
      skipDuplicateSuspicion: `${SERVER_BASE_URL}/customer-duplicate/skip/{tecCustomerId}`,
      mergeDuplicateSuspicion: `${SERVER_BASE_URL}/customer-duplicate/resolve/{tecCustomerIdToSave}/{tecCustomerIdToDelete}`,
      search: `${SERVER_BASE_URL}/customer/search`,
      setNewPreferredDealer: `${SERVER_BASE_URL}/customer/save/preferredDealer/{id}`,
      deleteByTecCustomerId: `${SERVER_BASE_URL}/customer/{tecCustomerId}/delete`,
      deleteCompletelyByTecCustomerId: `${SERVER_BASE_URL}/testing/customer/delete/`,
      blacklistByTecCustomerId: `${SERVER_BASE_URL}/customer/{tecCustomerId}/blacklist`,
      unBlacklistByTecCustomerId: `${SERVER_BASE_URL}/customer/{tecCustomerId}/un-blacklist`,
      dealerSearch: `${SERVER_BASE_URL}/dealer/search/{consentId}`,
      uploadCSV: `${SERVER_BASE_URL}/customer/upload-csv`,
      uploadPropectiveCSV: `${SERVER_BASE_URL}/customer/upload-prospective-csv`,
      uploadCSVTest: `${SERVER_BASE_URL}/customer/test-upload-csv`,
      createCustomer: `${SERVER_BASE_URL}/customer`,
      saveCustomerNumber: `${SERVER_BASE_URL}/customer/customer-number`,
    },
    CONSENT: {
      deleteConsent: `${SERVER_BASE_URL}/consent/delete/{id}`,
      deleteTemporaryConsent: `${SERVER_BASE_URL}/consent/delete-temporary/{tecCustomerId}`,
      saveConsent: `${SERVER_BASE_URL}/consent/save`,
      uploadFile: `${SERVER_BASE_URL_FILE}/attachment/{tecCustomerId}`,
      downloadFile: `${SERVER_BASE_URL_FILE}/attachment/download/{attachmentId}`,
    },
    DEALER: {
      search: `${SERVER_BASE_URL}/dealer/search`,
      update: `${SERVER_BASE_URL}/dealer/update/{id}`,
      create: `${SERVER_BASE_URL}/dealer/create`,
      delete: `${SERVER_BASE_URL}/dealer/delete/{dealerNumber}/newPrefDealer/{newPrefDealerNumber}`,
      changeDealerNumber: `${SERVER_BASE_URL}/dealer/changeDealerNumber/{id}`,
      changeDealerGroup: `${SERVER_BASE_URL}/dealer/changeDealerGroup/{id}`,
      consentDealer: `${SERVER_BASE_URL}/dealer/search/consent/{id}`,
      saveDeleteConsentDealers: `${SERVER_BASE_URL}/dealer/save/delete/relationship/{consentId}`,
      saveAddedConsentDealers: `${SERVER_BASE_URL}/dealer/save/add/relationship/{consentId}`,
      getDealerByNumberAndNotDeleted: `${SERVER_BASE_URL}/dealer/number/{dealerNumber}`,
      getDealerByDealerNumber: `${SERVER_BASE_URL}/dealer/dealernumber/{dealerNumber}`,
      getKeycloakCredentialsUrl: `${SERVER_BASE_URL}/dealer/keycloak-credentials/{dealerNumber}`,
      generateKeycloakClientUrl: `${SERVER_BASE_URL}/dealer/generate-keycloak-client`,
      reloadConsentRelationShips: `${SERVER_BASE_URL}/dealer/reloadConsentRelationShips/{id}`,
      changeDealerNumberMainDealer: `${SERVER_BASE_URL}/dealer/changeDealerNumberMainDealer/{id}`,
      getOpenDealerDialogueLink: `${SERVER_BASE_URL}/dealer/dealer-frontend-link/dealernumber/{dealerNumber}/tecCustomerId/{tecCustomerId}`,
    },
    REPORT: {
      getMasterReport: `${SERVER_BASE_URL}/report/master/{interval}/{brandFilter}`,
      getDealerReport: `${SERVER_BASE_URL}/report/dealer/{preferredDealerOption}/{dealerNumber}/{brandFilter}/since/{interval}`,
      getConsentChangesReportSince: `${SERVER_BASE_URL}/report/consent/{dealerNumber}/{dealerOrGroup}/since/{interval}/{brandFilter}`,
      getCustomersFromConsentChangesReport: `${SERVER_BASE_URL}/report/consent/{dealerNumber}/{dealerOrGroup}/since/{interval}/{brandFilter}/{consentChange}`,
      getMasterFrontendReportSinceGraph: `${SERVER_BASE_URL}/report/customer-frontend/since/{interval}`,
      getReportFile: `${SERVER_BASE_URL}/report/dealer/csv`
    },
    SALESPERSON: {
      getSalesperson: `${SERVER_BASE_URL}/salesperson`,
      getMasterUsers: `${SERVER_BASE_URL}/master-users`,
    },
    NOTIFICATION: {
      createNotification: `${SERVER_BASE_URL}/notifications`,
      getNotifications: `${SERVER_BASE_URL}/notifications`,
      editNotifications: `${SERVER_BASE_URL}/notifications/{notificationId}`,
      deleteNotifications: `${SERVER_BASE_URL}/notifications/{notificationId}`,
    },
    PHONETICEQUAL: {
      createPhoneticEqual: `${SERVER_BASE_URL}/phoneticequal`,
      getPhoneticEqualList: `${SERVER_BASE_URL}/phoneticequal`,
      editPhoneticEqual: `${SERVER_BASE_URL}/phoneticequal/{phoneticEqualId}`,
      deletePhoneticEqual: `${SERVER_BASE_URL}/phoneticequal/{phoneticEqualId}`,
    },
    DEALERPILOT: {
      createDealerPilot: `${SERVER_BASE_URL}/dealerpilot`,
      getDealerPilotList: `${SERVER_BASE_URL}/dealerpilot`,
      editDealerPilot: `${SERVER_BASE_URL}/dealerpilot/{dealerPilotId}`,
      deleteDealerPilot: `${SERVER_BASE_URL}/dealerpilot/{dealerPilotId}`,
    },
  },
  fileUpload: {
    allowedContentTypes:
      'image/jpeg,image/png,image/gif,image/bmp,image/x-bmp,image/x-ms-bmp,image/tiff,image/heic,application/pdf,application/vnd.ms-outlook',
    allowedFileExtensions: [
      '.jpg',
      '.jpeg',
      '.png',
      '.gif',
      '.bmp',
      '.tiff',
      '.heic',
      '.pdf',
      '.msg',
    ],
  },
  userlaneKey: 'djg7l6l84m'
};
