import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CustomerSearchService } from './services/customer-search.service';
import { ButtonComponent } from './components/button/button.component';
import { DealerNamePipe } from './pipes/dealer-name.pipe';
import { DealerService } from './services/dealer.service';
import {NgxSpinnerModule} from 'ngx-spinner';
import { PanelComponent } from './components/panel/panel.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { BusinessPipe } from './pipes/business.pipe';
import {ShortDatePipe} from './pipes/short-date.pipe';
import {DashedPipe} from './pipes/dashed.pipe';
import {CustomerDuplicateComponent} from './components/customer-duplicate/customer-duplicate.component';
import {DataTableModule} from '../shared/data-table/data-table.module';
import {FlexModule} from '@ngbracket/ngx-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormTextInputComponent} from './components/form-text-input/form-text-input.component';
import {FormSelectComponent} from './components/form-select/form-select.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ModalComponent} from './modal/modal.component';

@NgModule({
  declarations: [
    ButtonComponent,
    FormTextInputComponent,
    FormSelectComponent,
    DealerNamePipe,
    PanelComponent,
    BusinessPipe,
    ShortDatePipe,
    DashedPipe,
    CustomerDuplicateComponent,
    ModalComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    NgxSpinnerModule,
    FontAwesomeModule,
    CommonModule,
    DataTableModule,
    FlexModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule
  ],
  exports: [
    ButtonComponent,
    DealerNamePipe,
    NgxSpinnerModule,
    PanelComponent,
    FontAwesomeModule,
    BusinessPipe,
    ShortDatePipe,
    DashedPipe,
    CustomerDuplicateComponent,
    FormTextInputComponent,
    FormSelectComponent,
    ModalComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        CustomerSearchService,
        DealerService
      ]
    };
  }
}
